import React, {useEffect} from 'react';
import {useTheme} from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Cookies from "js-cookie";
import Typography from "@mui/material/Typography";

import CristalLogoLight from './../images/logo-cristalhub-light.svg';
import CristalLogoDark from './../images/logo-cristalhub-dark.svg';
import IconBackDark from './../images/icon-arrow-left-back-dark.svg';
import IconBackLight from './../images/icon-arrow-left-back-light.svg';

import * as constantes from "./Parts/AlveoleConstants";
import axios from "axios";
import {
    CristalBackBleuButton,
    CristalBlueButton,
    CristalLogo,
    CristalPinkButton, CristalWhiteButton,
    StyledBadge, useStyles
} from "./Parts/ComponentLayout";
import NotificationMenu from "./Parts/NotificationMenu";


const Layout = ({children}) => {

    const classes = useStyles();
    const [bLoading, setBLoading] = React.useState(true);
    const [anchorEl] = React.useState(null);
    const [calendars, setCalendars] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [bShowNotifications, setBShowNotifications] = React.useState(false); //fenetre d'affichage notifications
    const [bConsents, setBConsents] = React.useState(false);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const moment = require('moment');
    require('moment/locale/fr');

    useEffect(() => {
        const timer = setTimeout(() => {
            setBLoading(false);
        }, 4000); //  milliseconds
        return () => clearTimeout(timer); // Annule le timer si le composant est démonté avant le délai
    }, []);

    const fOpenNotifications = () => {
        setBShowNotifications(true);
    }
    const fCloseNotifications = () => {
        setBShowNotifications(false);
    }
    const fRemoveNotifications = (value) => {
        // calendars = calendars.filter((calendar) => calendar['@id'] === response.data['notification']['@id'])
        axios.post(`${constantes.zUrlBackNotifications}/remove-notification`, {
                idUser: Cookies.get('idUser'),
                idNotification: value
            },
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            })
            .then((response) => {
                if (response.data['notification']['type'] === 'calendar') {
                    setCalendars(calendars.filter((calendar) => calendar['externalId'] !== value));
                } else {
                    setDocuments(documents.filter((doc) => doc['externalId'] !== value));
                }
            })
            .catch((error) => {
                console.log(error)
            })
        ;
    }

    // Recuperation des notifications
    const fetchNotifications = () => {
        setBLoading(true);
        axios.post(`${constantes.zUrlBackNotifications}/notifications`,
            {
                token: Cookies.get('token'),
                societe: Cookies.get('socId'),
                compte: Cookies.get('accountId'),
                idUser: Cookies.get('idUser')
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                if (response.status === 200) {
                    // Récupération et conversion des dates du calendrier
                    const calendarList = response.data['calendarList'].map(event => {
                        event.dateEvenement = moment.utc(event.dateEvenement, 'DD-MM-YYYY hh:mm').local().format('D MMMM YYYY HH:mm');
                        return event;
                    });
                    setCalendars(calendarList);
                    // Récupération Datas documents
                    setDocuments(response.data['zeenDocsList']);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setBLoading(false);
            });
    };


    useEffect(() => {
        let accountAcces;
        axios.post(`${constantes.zUrlBackNotifications}/customer-check`,
            {
                token: Cookies.get('token'),
                accountName: Cookies.get('compteParamNom')
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(response => {
                if (response.status === 200) {
                    accountAcces = response.data['accountAcces'];
                }

            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                if (accountAcces !== null) {
                    fetchNotifications();
                }
                setBLoading(false);
            });

    }, []);


    useEffect(() => {
        axios.post(`${constantes.zUrlBackNotifications}/notifications-consents`,
            {token: Cookies.get('token')}, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },

            })
            .then(response => {
                let notificationValue = response.data['consentNotif'];
                setBConsents(notificationValue);
            })
            .catch(error => {
                console.error(error);
            });
        setBLoading(false);
    }, []);


    const handleCloseLoading = () => {
        setBLoading(false);
    };

    const handleHome = () => {
        window.location.assign(constantes.zURLHome);
    }
    const handleRetour = () => {
        window.history.back();
    }
    const handleProfile = () => {
        window.location.assign(constantes.zURLprofile)
    }
    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl">
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={bLoading}
                    onClick={handleCloseLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Box sx={{flexGrow: 1}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{margin: '36px 0px 14px'}}
                    >
                        <Grid size={6}>
                            <CristalLogo
                                src={theme.palette.mode === 'light' ? `${CristalLogoLight}` : `${CristalLogoDark}`}
                                loading="lazy" sx={{maxWidth: '100%'}}/>
                        </Grid>
                        {Cookies.get('socId') && Cookies.get('accountId') && (
                            <Grid container
                                  size={6}
                                  direction="row"
                                  flexWrap="nowrap"
                                  justifyContent="flex-end"
                                  alignItems="center">
                                {calendars.length === 0 && documents.length === 0 ? (

                                    <CristalBlueButton
                                        disableRipple
                                        sx={{
                                            cursor: 'default',
                                            marginRight: '.5rem!important'
                                        }}
                                    >
                                        <img src={require('./../images/icon-notif.svg').default}
                                             alt="CristalHub"
                                             className="CristalNotifButton"/>
                                    </CristalBlueButton>

                                ) : (
                                    <>
                                        <Box>
                                            <StyledBadge theme
                                                         badgeContent=" "
                                                         overlap="circular"
                                                         sx={{
                                                             '& .MuiBadge-badge':
                                                                 {border: theme.palette.mode === 'light' ? '2px solid #E5F9FE' : '2px solid #003350'}
                                                         }}
                                            >
                                                <CristalBlueButton
                                                    disableRipple
                                                    onClick={fOpenNotifications}
                                                    sx={{marginRight: '.5rem!important'}}
                                                >
                                                    <img
                                                        src={require('./../images/icon-notif.svg').default}
                                                        alt="CristalHub"
                                                        className="CristalNotifButton"/>
                                                </CristalBlueButton>
                                            </StyledBadge>
                                        </Box>
                                        <NotificationMenu
                                            anchorEl={anchorEl}
                                            bShowNotifications={bShowNotifications}
                                            fCloseNotifications={fCloseNotifications}
                                            fRemoveNotifications={fRemoveNotifications}
                                            calendars={calendars}
                                            documents={documents}
                                        />
                                    </>
                                )}
                                {bConsents === true ? (
                                    <Box>
                                        <StyledBadge
                                            classes={{badge: classes.customBadge}}
                                            badgeContent=" "
                                            overlap="circular"
                                            sx={{
                                                '& .MuiBadge-badge':
                                                    {border: theme.palette.mode === 'light' ? '2px solid #E5F9FE' : '2px solid #003350'}
                                            }}>
                                            <CristalBlueButton disableRipple
                                                               onClick={handleProfile}>
                                                <img
                                                    src={require('./../images/icon-settings.svg').default}
                                                    alt="CristalHub"
                                                    className="CristalSettingsButton"/>
                                            </CristalBlueButton>
                                        </StyledBadge>
                                    </Box>
                                ) : (
                                    <CristalBlueButton disableRipple
                                                       onClick={handleProfile}>
                                        <img
                                            src={require('./../images/icon-settings.svg').default}
                                            alt="CristalHub"
                                            className="CristalSettingsButton"/>
                                    </CristalBlueButton>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    <Grid container className="IconsNavigationContainer"
                          direction="row" md={12} xs={12}>
                        <CristalPinkButton
                            onClick={handleHome}
                            disableRipple>
                            <img src={require('./../images/icon-home-blc.svg').default}
                                 alt="CristalHub"
                                 className="CristalIconButton"/>
                        </CristalPinkButton>
                        <CristalBackBleuButton
                            onClick={handleRetour}
                            disableRipple>
                            <img
                                src={theme.palette.mode === 'light' ? `${IconBackLight}` : `${IconBackDark}`}
                                alt="CristalHub"
                                className="CristalIconButton"/>
                        </CristalBackBleuButton>
                        <CristalWhiteButton
                            disableRipple
                            sx={{marginLeft: '15px'}}>
                            <img src={require('./../images/icon-alv-dmd-pdts.svg').default}
                                 alt="CristalHub"
                                 className="CristalIconButton"
                            />
                        </CristalWhiteButton>
                        <Grid container
                              direction="column"
                              justifyContent="center"
                              alignItems="flex-start"
                              sx={{marginLeft: '11px', marginTop: '5px'}}>
                            <Typography>
                                Vous êtes sur...
                            </Typography>
                            <Typography>
                                <span> {constantes.vousetessur} </span>
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*  Contenu spécifique de chaque composant */}
                    {React.Children.map(children, child =>
                        React.cloneElement(child)
                    )}
                </Box>
            </Container>
        </>
    );
}
export default Layout;
